<template>
  <div class="row justify-center">
    <div
      class="col-lg-12 col-xl-12 col-md-12 col-sm-10 col-xs-10 q-gutter-y-lg"
    >
      <h5 class="title">Page not found</h5>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Page404View",

  components: {},

  setup() {
    return {
      //states
      //handlers
    };
  },
});
</script>
<style scoped lang="scss">
.title {
  text-align: center;
}
</style>
